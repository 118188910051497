import React from 'react';
import '../../App.css';
import SearchServices from '../searchServices/list';

function Services() {
  return (
    <>
      <SearchServices />;
    </>
  );
}

export default Services;